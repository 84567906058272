import { Reducer } from 'redux'

import { NotificationActionTypes, NotificationsState } from './types'
import { ActionNotificationObjectTypes } from './action'

const initialNotificationState: NotificationsState = {
  notificationEnabled: false,
  hasNewNotification: false,
  hasNewNotificationFirebase: false,
  currentNotification: undefined,
  paging: {
    content: [],
    last: undefined,
    size: undefined,
    page: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },
  settings: {
    enabled: undefined,
    phoneAllowed: undefined,
    categories: [],
  },
}

export const notificationReducer: Reducer<NotificationsState, ActionNotificationObjectTypes> = (state = initialNotificationState, action) => {
  switch (action.type) {
    case NotificationActionTypes.STORE_NOTIFICATION_ENABLED:
      return {
        ...state,
        notificationEnabled: action.payload.enabled,
        token: action.payload.token,
      }
    case NotificationActionTypes.STORE_NOTIFICATIONS:
      return {
        ...state,
        paging: {
          last: action.payload.notificationsResult.last,
          page: action.payload.notificationsResult.page,
          size: action.payload.notificationsResult.size,
          totalElements: action.payload.notificationsResult.totalElements,
          totalPages: action.payload.notificationsResult.totalPages,
          content: [...(state.paging ? state.paging.content : []), ...action.payload.notificationsResult.content],
        },
      }
    case NotificationActionTypes.STORE_CLEAR_NOTIFICATIONS:
      return {
        ...state,
        paging: {
          last: action.payload.notificationsResult.last,
          page: action.payload.notificationsResult.page,
          size: action.payload.notificationsResult.size,
          totalElements: action.payload.notificationsResult.totalElements,
          totalPages: action.payload.notificationsResult.totalPages,
          content: [...action.payload.notificationsResult.content],
        },
      }
    case NotificationActionTypes.STORE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        settings: {
          enabled: action.payload.settings.enabled,
          phoneAllowed: action.payload.settings.phoneAllowed,
          message: action.payload.settings.message,
          categories: action.payload.settings.categories,
        },
      }
    case NotificationActionTypes.STORE_HAS_NEW_NOTIFICATION:
      return {
        ...state,
        hasNewNotification: action.payload,
      }
    case NotificationActionTypes.STORE_HAS_NEW_NOTIFICATION_FIREBASE:
      return {
        ...state,
        hasNewNotificationFirebase: action.payload,
      }
    case NotificationActionTypes.STORE_ONE_NOTIFICATION:
      return {
        ...state,
        currentNotification: action.payload,
      }
    default:
      return state
  }
}

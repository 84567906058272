import { storeManager } from '../../lib/storeManager'
// import { Routes } from '../../navigation/Routes'
import { NotificationActions } from '../../redux/notification/action'
// import { navigate } from '../navigation'
// import RNBootSplash from 'react-native-bootsplash'
// import messagingModule, { FirebaseMessagingTypes } from '@react-native-firebase/messaging'
// import PushNotificationIOS from '@react-native-community/push-notification-ios'
// import { Platform } from 'react-native'

// let remoteNotificationOpenedListener: Function

/**
 * CheckNotificationPermission :
 * 1 - Vérifie qu'on a les permissions
 * 2 - Si on a pas les permissions, on demande les permissions
 * 3 - Envoi dans tous les cas la token au CMS
 */
export const checkNotificationPermission = async () => {
  // try {
  //   const authStatus = await messagingModule().requestPermission()
  //   const enabled =
  //     authStatus === messagingModule.AuthorizationStatus.AUTHORIZED ||
  //     authStatus === messagingModule.AuthorizationStatus.PROVISIONAL
  //   if (enabled) {
  //     sendToken()
  //   } else {
  //     // RNBootSplash.hide()
  //     sendToken()
  //   }
  // } catch (error) {
  //   sendEmptyToken()
  // }
  // runNotificationListeners()
}

export const sendToken = async () => {
  // const fcmToken = await messagingModule().getToken()
  // storeManager.store.dispatch(NotificationActions.sendToken(fcmToken))
  // storeManager.store.dispatch(NotificationActions.loadHaveNotification())
}

export const sendEmptyToken = () => {
  storeManager.store.dispatch(NotificationActions.sendToken(null))
}

export const runNotificationListeners = async () => {
  // messagingModule()
  //   .getInitialNotification()
  //   .then(remoteMessage => {
  //     if (remoteMessage) {
  //       navigate(Routes.Notifications, remoteMessage.data ? { id: remoteMessage.data.id } : {})
  //     }
  //   })
  // remoteNotificationOpenedListener = messagingModule().onNotificationOpenedApp(() => {
  //   navigate(Routes.Notifications, {})
  // })
  /**
   * se déclenche quand l'user reçoit la notification avec l'appli ouverte
   * If the RemoteMessage payload contains a notification property when sent to the onMessage handler,
   * the device will not show any notification to the user -> c'est le cas si il y a un title et un body dans la notification
   * -> https://rnfirebase.io/messaging/usage
   *
   * TODO
   * METTRE À JOUR la cloche de notification
   */
  // messagingModule().onMessage(async (notification: FirebaseMessagingTypes.RemoteMessage) => {
  //   if (notification && notification.data) {
  //     storeManager.store.dispatch(NotificationActions.getOneNotification(notification.data.id))
  //     storeManager.store.dispatch(NotificationActions.storeHasNewNotificationFirebase(true))
  //   }
  // })
}

// const removeNotificationListener = () => {
//   // messagingModule()
//   //   .unsubscribeFromTopic('Attractive-Channel')
//   //   .then(() => {
//   //     storeManager.store.dispatch(NotificationActions.loadHaveNotification())
//   //     storeManager.store.dispatch(NotificationActions.storeHasNewNotificationFirebase(true))
//   //   })
//   // if (Platform.OS === 'ios') {
//   //   // PushNotificationIOS.removeEventListener('notification')
//   // }
// }

export const removeNotificationListeners = () => {
  // remoteNotificationOpenedListener()
  // removeNotificationListener()
}

import { Container, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../components/Button'
import { CloseButton } from '../../components/CloseButton'
import { OTPInput } from '../../components/OTPInput'
import { TextInput } from '../../components/TextInput'
import { useSignIn } from '../../hooks/useSignIn'
import { i18n } from '../../lib/i18n'
import { Routes } from '../../navigation/Routes'
import { AuthenticationActions } from '../../redux/authentication/actions'
import { set2fa } from '../../redux/authentication/selectors'
import { menuSelector } from '../../redux/menu/selector'
import { RootState } from '../../redux/reducers'
import { useKeyboardHeight } from '../../services/keyboard'
import { navigate } from '../../services/navigation'
import { theme } from '../../theme'

const LOGO_HEIGHT = 76

const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    fontWeight: '700',
    lineHeight: 30,
    color: theme.colors.black,
    marginBottom: theme.padding.x3,
  },
  subtitle: {
    color: theme.colors.silverStrong,
    marginBottom: theme.padding.x4,
  },
  input: {
    marginBottom: theme.padding.x3,
  },
  input2fa: {
    width: '10%',
  },
  forgetPassword: {
    alignSelf: 'flex-end',
    color: theme.colors.silverStrong,
    marginBottom: theme.padding.x3,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  guestButton: {
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderColor: theme.colors.silverStrong,
    marginTop: theme.padding.x2,
  },
  googleButton: {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.silverStrong,
    borderWidth: 1,
    marginBottom: theme.padding.x2,
  },
  orContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: theme.padding.x3,
  },
  separator: {
    flex: 1,
    borderTopWidth: 1,
    borderColor: theme.colors.silver,
  },
  or: {
    marginHorizontal: theme.padding.x3,
    color: theme.colors.silver,
  },
  spacer: {
    height: theme.padding.x6,
  },
  bottomText: {
    paddingTop: theme.padding.x3,
    alignSelf: 'center',
    flexDirection: 'row',
  },
  container: {
    maxWidth: '100%',
    flex: 1,
  },
  containerView: {
    width: '100%',
    marginBottom: 50,
  },
  scrollview: {
    padding: theme.padding.x3,
    width: '100%',
  },
})

export const SignIn: FunctionComponent = () => {
  const menu = useSelector(menuSelector)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const keyboardHeight = useKeyboardHeight()
  const { doSignIn, loading } = useSignIn()
  const has2fa = useSelector((state: RootState) => set2fa(state))
  const [otp, setOtp] = useState<string>('')
  const dispatch = useDispatch()

  useEffect(
    () => () => {
      dispatch(AuthenticationActions.set2fa(false))
    },
    [],
  )

  useEffect(
    () => () => {
      setOtp('')
    },
    [has2fa],
  )

  const logoUri = menu && menu.header && menu.header.image

  return (
    <Container safeArea background={theme.colors.white} style={styles.container}>
      <KeyboardAwareScrollView style={styles.scrollview}>
        <View style={styles.containerView}>
          {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
          <CloseButton />
          <Text style={styles.title}>{i18n.t('signIn.welcome')}</Text>
          <Text style={styles.subtitle}>{i18n.t('signIn.subtitle')}</Text>
          <TextInput
            label={i18n.t('signIn.email.label')}
            isMail
            placeholder={i18n.t('signIn.email.placeholder')}
            value={email}
            autoCapitalize="none"
            keyboardType="email-address"
            autoComplete="email"
            autoCorrect={false}
            onValueChanged={text => setEmail(text)}
            style={styles.input}
          />
          <TextInput
            label={i18n.t('signIn.password.label')}
            placeholder={i18n.t('signIn.password.placeholder')}
            value={password}
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry
            onValueChanged={text => setPassword(text)}
            style={styles.input}
          />
          {has2fa && (
            <>
              <Text mb={4}>Code de vérification</Text>
              <OTPInput currentCode={otp} setCurrentCode={setOtp} />
            </>
          )}
          <Text style={styles.forgetPassword} onPress={() => navigate(Routes.ResetPasswordSendEmail)}>
            {i18n.t('signIn.forgetPassword')}
          </Text>
          <Button
            onPress={() => doSignIn(email, password, otp || null)}
            label={i18n.t('signIn.connectWithEmail')}
            style={{ backgroundColor: theme.colors.lightBlue }}
            iconName="mail"
            loading={loading}
          />
          {Platform.OS === 'android' && has2fa && <View style={{ height: keyboardHeight }} />}
        </View>
      </KeyboardAwareScrollView>
    </Container>
  )
}

import { StackScreenProps } from '@react-navigation/stack'
import { Container, ScrollView, Text } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Button } from '../../components/Button'
import { CloseButton } from '../../components/CloseButton'
import { TextInput } from '../../components/TextInput'
import { useSignIn } from '../../hooks/useSignIn'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { menuSelector } from '../../redux/menu/selector'
import { isPasswordValid } from '../../services/form/formValidation'
import { hasGoodLenth, hasLowerCase, hasNumber, hasSpecialCharactere, hasUpperCase } from '../../services/form/formValidation/passwordValidation/passwordValidation'
import { theme } from '../../theme'
import { ValidationItem } from './components/ValidationItem'
import { useResetPassword } from './useResetPassword'

const LOGO_HEIGHT = 76

const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    fontWeight: '700',
    lineHeight: 45,
    color: theme.colors.black,
    marginBottom: theme.padding.x3,
  },
  input: {
    marginBottom: theme.padding.x3,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
  },
  container: {
    maxWidth: '100%',
    flex: 1,
  },
  scrollview: {
    width: '100%',
    padding: theme.padding.x3,
  },
})

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('password_required')
    .test('minLength', '', value => hasGoodLenth.test(value))
    .test('lowercase', '', value => hasLowerCase.test(value))
    .test('uppercase', '', value => hasUpperCase.test(value))
    .test('number', '', value => hasNumber.test(value))
    .test('specialChar', '', value => hasSpecialCharactere.test(value)),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'passwordsMatch')
    .required('confirm_password_required'),
})

export const ResetPassword: FunctionComponent<StackScreenProps<ActionViewParamsType, 'ResetPassword'>> = ({ route }) => {
  const { token, email, code, isDoubleAuthentication } = route.params
  const menu = useSelector(menuSelector)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { doResetPassword, loading: resetPasswordLoading } = useResetPassword()
  const { doSignIn, loading: signInLoading } = useSignIn()

  const logoUri = menu && menu.header && menu.header.image

  const [validationResult, setValidationResult] = useState<string[]>([])

  useEffect(() => {
    validationSchema
      .validate({ password, confirmPassword }, { abortEarly: false })
      .then(() => setValidationResult([]))
      .catch(err => {
        const errorKeys = err.inner.map(error => {
          return error.params && error.params.path === 'password' ? error.type : error.path
        })
        setValidationResult(errorKeys)
      })
  }, [password, confirmPassword])

  const isCriteriaValidated = criteriaKey => {
    return !validationResult.includes(criteriaKey)
  }

  const isPasswordAllowed = isCriteriaValidated('confirmPassword') && isPasswordValid(password)

  const handleResetPassword = async () => {
    if (!isPasswordAllowed) return

    await doResetPassword(token, password)
    if (isDoubleAuthentication) {
      await doSignIn(email, password, code)
    } else {
      await doSignIn(email, password)
    }
  }

  return (
    <Container safeArea background={theme.colors.white} style={styles.container}>
      <ScrollView style={styles.scrollview}>
        {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
        <CloseButton />

        <Text style={styles.title}>{i18n.t('resetPassword.title')}</Text>

        <TextInput
          label={i18n.t('resetPassword.password.label')}
          placeholder={i18n.t('resetPassword.password.placeholder')}
          value={password}
          onValueChanged={setPassword}
          autoCapitalize="none"
          secureTextEntry
          autoCorrect={false}
          style={styles.input}
        />

        <TextInput
          label={i18n.t('resetPassword.confirmPassword.label')}
          placeholder={i18n.t('resetPassword.confirmPassword.placeholder')}
          value={confirmPassword}
          onValueChanged={setConfirmPassword}
          secureTextEntry
          autoCapitalize="none"
          autoCorrect={false}
          style={styles.input}
        />
        <ValidationItem validated={isCriteriaValidated('lowercase') && isCriteriaValidated('uppercase')} text="Majuscules et minuscules utilisées" />
        <ValidationItem validated={isCriteriaValidated('minLength')} text="8 caractères ou plus" />
        <ValidationItem validated={isCriteriaValidated('specialChar')} text="Au moins un caractère spécial (!@$*...)" />
        <ValidationItem validated={isCriteriaValidated('number')} text="Au moins un chiffre (0-9)" />
        <ValidationItem validated={isCriteriaValidated('confirmPassword')} text="Les mots de passe correspondent" />

        <Button
          onPress={handleResetPassword}
          label={i18n.t('resetPassword.connect')}
          style={{ backgroundColor: theme.colors.lightBlue, marginBottom: theme.padding.x2, marginTop: theme.padding.x3 }}
          loading={resetPasswordLoading || signInLoading}
          disabled={!isPasswordAllowed}
        />
      </ScrollView>
    </Container>
  )
}

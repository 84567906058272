import { Checkbox, Text } from 'native-base'
import React, { PureComponent } from 'react'
import { Platform, StyleSheet, TouchableWithoutFeedback } from 'react-native'
import { FormSingleCheckbox } from '../../../api/Form/types'
import { ThemeState } from '../../../redux/theme/types'
import { theme } from '../../../theme'
import { Body } from '../../NativeBaseLegacy'
import { FormElementProps } from '../types'

type SingleCheckboxProps = FormElementProps<FormSingleCheckbox, boolean> & ThemeState

class SingleCheckboxFieldClass extends PureComponent<SingleCheckboxProps> {
  public focus = () => {}

  public render() {
    const { field, value, setFieldValue } = this.props
    const setCheckbox = () => setFieldValue(this.elementId, !value)
    const color = this.props.toolbarDefaultBg || theme.colors.silver

    return (
      <TouchableWithoutFeedback onPress={setCheckbox}>
        <Body style={styles.body}>
          <Text style={styles.label}>{field.libelle}</Text>
          <Checkbox
            {...this.props}
            value={(value || 0).toString()}
            isChecked={value}
            color={color}
            style={[styles.checkbox, { borderColor: color }]}
            onChange={() => {
              if (Platform.OS !== 'web') setCheckbox()
            }}
          />
        </Body>
      </TouchableWithoutFeedback>
    )
  }

  private get elementId() {
    const { field } = this.props
    return `${field.id}`
  }
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
  },
  checkbox: {
    marginRight: 0,
  },
  label: {
    flex: 1,
    paddingRight: theme.padding.x4,
  },
})

export { SingleCheckboxFieldClass }

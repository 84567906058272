export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '30fa5feb-aca5-48a4-b9fd-2f976d4f0761',
  ENV: 'saintDoulchard',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: false,
  },
  matomoUrlBase: 'https://analytics.tools.citopia.fr/',
  matomoSiteId: 68,
}

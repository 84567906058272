import isEmpty from 'lodash/isEmpty'
import { View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import MapView, { Marker, Polyline } from 'react-native-maps'
import { useSelector } from 'react-redux'
import { MarkerBeaconResult } from '../../api/CartographyBeacons/type'
import { cartographyBeaconSelector } from '../../redux/cartographyBeacons/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { buildActionReferentiel } from '../../services/ActionBuilder'
import { fitToCoordinates, getPolyMapResultCoordinates } from '../../services/cartography'
import { checkLocation } from '../../services/Permissions/Permissions.service'
import { theme } from '../../theme'
import { CartographyLoading } from '../CartographyLoading'
import { ModalBottomSimple } from '../ModalBottomSimple'

interface CartographyBeaconProps {
  id: number
}

const DEFAULT_STROKE_WIDTH = 3

export const CartographyBeacon: FunctionComponent<CartographyBeaconProps> = ({ id }) => {
  const mapViewRef = React.createRef<MapView>()
  const [isMapReady, setIsMapReady] = useState(false)
  const [selectedMarker, setSelectedMarker] = useState<MarkerBeaconResult | null>(null)

  const cartographyConfig = useSelector((state: RootState) => cartographyBeaconSelector(state, id.toString()))

  useEffect(() => fitToPolylines(), [cartographyConfig, isMapReady])

  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_CARTOGRAPHY_BEACON))

  const markers = cartographyConfig && cartographyConfig.markers ? cartographyConfig.markers : []

  const fitToPolylines = () => {
    if (!mapViewRef.current || !cartographyConfig || !cartographyConfig.polylines || isEmpty(cartographyConfig.polylines) || !isMapReady) {
      return
    }

    fitToCoordinates(mapViewRef, getPolyMapResultCoordinates(cartographyConfig.polylines), theme.padding.x4)
  }

  const renderZonePolylines = () => {
    if (!cartographyConfig || !cartographyConfig.polylines) {
      return null
    }

    return cartographyConfig.polylines.map((polyline, index) => {
      if (!polyline.coordinates) {
        return null
      }

      const fillColor = theme.colors.transparent
      const strokeColor = polyline.strokeColor || theme.colors.blue
      const strokeWidth = polyline.strokeWidth || DEFAULT_STROKE_WIDTH

      return <Polyline key={polyline.code || index} coordinates={polyline.coordinates} fillColor={fillColor} strokeColor={strokeColor} strokeWidth={strokeWidth} tappable />
    })
  }

  const renderMarker = (marker: MarkerBeaconResult) => {
    const selected = selectedMarker === marker
    // see https://github.com/react-native-community/react-native-maps/blob/master/docs/marker.md
    const anchor = { x: 0.5, y: 1 }
    /**
     * We use a selectedMarkerImage with a container to set the marker size
     * as the maximum size an image can be set, then we can change the size of the image
     * when the marker is presssed or not.
     */
    return (
      <Marker anchor={anchor} calloutAnchor={anchor} calloutOffset={anchor} coordinate={marker.latlng} onPress={() => setSelectedMarker(marker)} key={marker.id}>
        <View style={[styles.markerImageContainer, styles.selectedMarkerImage]}>
          <FastImage style={selected ? styles.selectedMarkerImage : styles.markerImage} source={{ uri: marker.point }} resizeMode={FastImage.resizeMode.contain} />
        </View>
      </Marker>
    )
  }

  const onLayout = () => {
    setIsMapReady(true)
  }

  const buildModalBottom = () => {
    if (selectedMarker && selectedMarker.idReferentiel) {
      return (
        <ModalBottomSimple
          itemAction={buildActionReferentiel(selectedMarker.idReferentiel, selectedMarker.libelle, selectedMarker.image)}
          title={selectedMarker.libelle}
          onClose={() => setSelectedMarker(null)}
          isVisible={selectedMarker !== null}
        />
      )
    }
    return null
  }

  return (
    <View style={styles.viewContainer}>
      {isLoading ? <CartographyLoading /> : null}
      <MapView
        ref={mapViewRef}
        style={styles.viewMap}
        showsPointsOfInterest={false}
        showsUserLocation
        showsCompass
        showsTraffic={false}
        zoomEnabled
        onLayout={onLayout}
        onMapReady={checkLocation}
      >
        {isMapReady && renderZonePolylines()}
        {isMapReady && markers && markers.map(marker => renderMarker(marker))}
      </MapView>
      {buildModalBottom()}
    </View>
  )
}

const styles = StyleSheet.create({
  viewContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  viewMap: {
    flex: 1,
    height: theme.deviceHeight,
    width: theme.deviceWidth,
  },
  markerImageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  markerImage: theme.markerImage,
  selectedMarkerImage: theme.selectedMarkerImage,
})

import { NotificationsResult, NotificationUserCategorie } from '../../api/Notification/types'
import { ItemAction } from '../../api/types'

export type notificationDB = {
  id: number
  libelle: string
  message: string
  image: string
  sendDate: null | number
  categorie: { id: number; libelle: string }
  action: ItemAction | null
}

export type NotificationsState = {
  notificationEnabled: boolean
  token?: string | null
  hasNewNotification: boolean
  hasNewNotificationFirebase: boolean
  currentNotification: notificationDB | undefined
  paging: NotificationsResult
  settings: {
    enabled?: boolean
    phoneAllowed?: boolean
    message?: string
    categories: NotificationUserCategorie[]
  }
}

export enum NotificationActionTypes {
  SEND_NOTIFICATION_TOKEN = 'SEND_NOTIFICATION_TOKEN',
  STORE_NOTIFICATION_ENABLED = 'STORE_NOTIFICATION_ENABLED',
  LOAD_HAS_NEW_NOTIFICATION = 'LOAD_HAS_NEW_NOTIFICATION',
  STORE_HAS_NEW_NOTIFICATION = 'STORE_HAS_NEW_NOTIFICATION',
  STORE_HAS_NEW_NOTIFICATION_FIREBASE = 'STORE_HAS_NEW_NOTIFICATION_FIREBASE',
  CLEAR_HAS_NEW_NOTIFICATION = 'CLEAR_HAS_NEW_NOTIFICATION',
  LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS',
  STORE_CLEAR_NOTIFICATIONS = 'STORE_CLEAR_NOTIFICATIONS',
  STORE_NOTIFICATIONS = 'STORE_NOTIFICATIONS',
  REFRESH_NOTIFICATIONS = 'REFRESH_NOTIFICATIONS',
  LOAD_NOTIFICATION_SETTINGS = 'LOAD_NOTIFICATION_SETTINGS',
  STORE_NOTIFICATION_SETTINGS = 'STORE_NOTIFICATION_SETTINGS',
  NOTIFICATION_ALL_ENABLED = 'NOTIFICATION_ALL_ENABLED',
  NOTIFICATION_CATEGORIE_ENABLED = 'NOTIFICATION_CATEGORIE_ENABLED',
  STORE_CURRENT_NOTIFICATION = 'STORE_CURRENT_NOTIFICATION',
  GET_ONE_NOTIFICATION = 'GET_ONE_NOTIFICATION',
  STORE_ONE_NOTIFICATION = 'STORE_ONE_NOTIFICATION',
}

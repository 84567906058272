import { Button, Icon, Modal, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { theme } from '../../theme'

const BORDER_RADIUS = 15
const MIN_HEIGHT = 300
const LINE_DOWN_WIDTH = 50
const LINE_DOWN__HEIGHT = 5
const LINE_VIEW_HEIGHT = 10

export interface DaySchedulesProps {
  title?: string
  content?: string
  fullscreen?: boolean
  show: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const ModalBottom: FunctionComponent<DaySchedulesProps> = ({ title, content, fullscreen, show, onClose, children }) => (
  <Modal style={styles.modal} isOpen={show} size="full" onClose={onClose}>
    <SafeAreaView style={fullscreen ? styles.safeView : styles.safeViewMinHeight}>
      {!fullscreen && (
        <View style={styles.emptyCollapse}>
          <View style={styles.emptyCollapseLine} />
        </View>
      )}
      <View style={[styles.content, fullscreen ? styles.fullscreen : {}]}>
        {title !== undefined && <Text style={styles.title}>{title}</Text>}
        {content !== undefined && <Text>{content}</Text>}
        {children}
        {fullscreen && (
          <Button variant="ghost" style={styles.closeButton} onPress={onClose}>
            <Icon as={Ionicons} name="md-close" size={6} />
          </Button>
        )}
      </View>
    </SafeAreaView>
  </Modal>
)

const styles = StyleSheet.create({
  safeView: {
    flex: 1,
    width: '100%',
    backgroundColor: theme.colors.white,
  },
  safeViewMinHeight: {
    backgroundColor: theme.colors.transparent,
    minHeight: MIN_HEIGHT,
  },
  fullscreen: {
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  modal: {
    width: '100%',
  },
  bottomModal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  emptyCollapse: {
    width: '100%',
    height: LINE_VIEW_HEIGHT,
    alignItems: 'center',
  },
  emptyCollapseLine: {
    height: LINE_DOWN__HEIGHT,
    width: LINE_DOWN_WIDTH,
    backgroundColor: theme.colors.white,
    borderRadius: BORDER_RADIUS,
  },
  content: {
    minHeight: MIN_HEIGHT,
    paddingHorizontal: theme.padding.x2,
    backgroundColor: theme.colors.white,
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
  },
  title: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.padding.x1,
    paddingBottom: theme.padding.x2,
    fontSize: theme.textSize.x3,
    lineHeight: theme.textSize.x3,
  },
})

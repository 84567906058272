/* eslint-disable @typescript-eslint/no-explicit-any */
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { Platform } from 'react-native'
import RNBootSplash from 'react-native-bootsplash'
import { HomeTabBar } from '../components/HomeTabBar'
import { Sidebar } from '../components/Sidebar'
import {
  Actualite,
  Actualites,
  AgendaList,
  AirQuality,
  Cantine,
  CantineElior,
  CartographieBeacon,
  CartographieZone,
  Cartography,
  CartographyRealTime,
  CGU,
  ChangePassword,
  CheatCodes,
  Cinema,
  CinemaSeance,
  CityAdd,
  CityAddConfig,
  CityEdit,
  EmailVerificationSent,
  ExternalLinks,
  FavoriesMultipleChoice,
  FavoriesShop,
  FavoriesSubThematic,
  Formulaire,
  GrcYpok,
  GridPage,
  Home,
  HorairesMarees,
  IntermediatePage,
  MyAccount,
  MyCitys,
  MyCitysEvents,
  MyTownHalls,
  Notifications,
  NotificationSettings,
  PersonalInfo,
  Promotion,
  Referentiel,
  ReferentielList,
  ResetPassword,
  ResetPasswordSendEmail,
  ResetPasswordVerification,
  RssFeed,
  Signalement,
  SignalementItem,
  SignalementList,
  SignIn,
  SignOut,
  SignUp,
  TaskReportItem,
  TaskReportList,
  UrgentNumbers,
  Weather,
  WeeklySchedules,
} from '../pages'
import { EmailVerificationDone } from '../pages/EmailVerificationDone'
import { MyAccountConfirmDeletion } from '../pages/MyAccountConfirmDeletion'
import { MyAccountDeletion } from '../pages/MyAccountDeletion'
import { MyAccountWasDeleted } from '../pages/MyAccountWasDeleted'
import { TaskReportItemRequest } from '../pages/TaskReportItemRequest'
import { setTopLevelNavigator, trackStateChange } from '../services/navigation'
import { Routes, RoutesCityAdd, RoutesCityConfig, RoutesHome, RoutesPromotion } from './Routes'

const Drawer = createDrawerNavigator()
const HomeTab = createBottomTabNavigator()
const StackMasterNavigator = createStackNavigator()
const StackAppNavigator = createStackNavigator()
const StackRootNavigator = createStackNavigator()

function HomeTabs() {
  return (
    <HomeTab.Navigator tabBar={props => <HomeTabBar {...props} />} screenOptions={{ headerShown: false }}>
      <HomeTab.Screen name={RoutesHome.Home} component={Home as any} />
      <HomeTab.Screen name={RoutesHome.CitysEvents} component={MyCitysEvents as any} />
      <HomeTab.Screen name={RoutesHome.MyTown} component={MyTownHalls as any} />
    </HomeTab.Navigator>
  )
}

function AppNavigator() {
  return (
    <StackAppNavigator.Navigator screenOptions={{ headerShown: false }}>
      <StackAppNavigator.Screen name={Routes.HomeTabs} component={HomeTabs} />
      <StackAppNavigator.Screen name={Routes.Notifications} component={Notifications as any} />
      <StackAppNavigator.Screen name={Routes.NotificationSettings} component={NotificationSettings} />
      <StackAppNavigator.Screen name={Routes.CheatCodes} component={CheatCodes as any} />
      <StackAppNavigator.Screen name={Routes.Gridpage} component={GridPage as any} />
      <StackAppNavigator.Screen name={Routes.IntermediatePage} component={IntermediatePage as any} />
      <StackAppNavigator.Screen name={Routes.ReferentielList} component={ReferentielList as any} />
      <StackAppNavigator.Screen name={Routes.Referentiel} component={Referentiel as any} />
      <StackAppNavigator.Screen name={Routes.HorairesMarees} component={HorairesMarees as any} />
      <StackAppNavigator.Screen name={Routes.AgendaList} component={AgendaList as any} />
      <StackAppNavigator.Screen name={Routes.Actualite} component={Actualite as any} />
      <StackAppNavigator.Screen name={Routes.Actualites} component={Actualites as any} />
      <StackAppNavigator.Screen name={Routes.Cartography} component={Cartography as any} />
      <StackAppNavigator.Screen name={Routes.CGU} component={CGU as any} />
      <StackAppNavigator.Screen name={Routes.Cantine} component={Cantine as any} />
      <StackAppNavigator.Screen name={Routes.CantineElior} component={CantineElior as any} />
      <StackAppNavigator.Screen name={Routes.CartographieZone} component={CartographieZone as any} />
      <StackAppNavigator.Screen name={Routes.CartographieBeacons} component={CartographieBeacon as any} />
      <StackAppNavigator.Screen name={Routes.CartographyRealTime} component={CartographyRealTime as any} />
      <StackAppNavigator.Screen name={Routes.Cinema} component={Cinema as any} />
      <StackAppNavigator.Screen name={Routes.Formulaire} component={Formulaire as any} />
      <StackAppNavigator.Screen name={Routes.UrgentNumbers} component={UrgentNumbers as any} />
      <StackAppNavigator.Screen name={Routes.WeeklySchedules} component={WeeklySchedules as any} />
      <StackAppNavigator.Screen name={Routes.SignalementList} component={SignalementList as any} />
      <StackAppNavigator.Screen name={Routes.Signalement} component={Signalement as any} />
      <StackAppNavigator.Screen name={Routes.SignalementItem} component={SignalementItem as any} />
      <StackAppNavigator.Screen name={Routes.ExternalLinks} component={ExternalLinks as any} />
      <StackAppNavigator.Screen name={Routes.CinemaSeance} component={CinemaSeance as any} />
      <StackAppNavigator.Screen name={Routes.GrcYpok} component={GrcYpok as any} />
      <StackAppNavigator.Screen name={Routes.RssFeed} component={RssFeed as any} />
      <StackAppNavigator.Screen name={Routes.MyCitys} component={MyCitys as any} />
      <StackAppNavigator.Screen name={Routes.Weather} component={Weather as any} />
      <StackAppNavigator.Screen name={Routes.AirQuality} component={AirQuality as any} />
      <StackAppNavigator.Screen name={Routes.TaskReportList} component={TaskReportList as any} />
      <StackAppNavigator.Screen name={Routes.TaskReportItem} component={TaskReportItem as any} />
      <StackAppNavigator.Screen name={Routes.TaskReportItemRequest} component={TaskReportItemRequest as any} />
      <StackAppNavigator.Screen name={Routes.FavoriesShop} component={FavoriesShop as any} />
      <StackAppNavigator.Screen name={Routes.FavoriesSubThematic} component={FavoriesSubThematic as any} />
      <StackAppNavigator.Screen name={Routes.FavoriesMultipleChoice} component={FavoriesMultipleChoice as any} />
    </StackAppNavigator.Navigator>
  )
}

function RootNavigator() {
  return (
    <StackRootNavigator.Navigator screenOptions={{ headerShown: false }}>
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name="AppNavigator" component={AppNavigator} />
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name={RoutesPromotion.Promotion} component={Promotion as any} />
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name={RoutesCityAdd.cityAdd} component={CityAdd as any} />
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name={RoutesCityAdd.cityAddConfig} component={CityAddConfig as any} />
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name={RoutesCityConfig.cityEdit} component={CityEdit as any} />
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name={Routes.MyAccount} component={MyAccount as any} />
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name={Routes.MyAccountDeletion} component={MyAccountDeletion} />
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name={Routes.MyAccountConfirmDeletion} component={MyAccountConfirmDeletion} />
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name={Routes.MyAccountWasDeleted} component={MyAccountWasDeleted} />
      <StackRootNavigator.Screen name={Routes.SignIn} component={SignIn} />
      <StackRootNavigator.Screen name={Routes.SignUp} component={SignUp} />
      <StackRootNavigator.Screen name={Routes.SignOut} component={SignOut} />
      <StackRootNavigator.Screen name={Routes.ResetPassword} component={ResetPassword as any} />
      <StackRootNavigator.Screen name={Routes.ResetPasswordSendEmail} component={ResetPasswordSendEmail} />
      <StackRootNavigator.Screen name={Routes.ResetPasswordVerification} component={ResetPasswordVerification as any} />
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name={Routes.PersonalInfo} component={PersonalInfo} />
      <StackRootNavigator.Screen options={{ presentation: 'modal' }} name={Routes.ChangePassword} component={ChangePassword} />
      <StackRootNavigator.Screen name={Routes.EmailVerificationSent} component={EmailVerificationSent as any} />
      <StackRootNavigator.Screen name={Routes.EmailVerificationDone} component={EmailVerificationDone} />
    </StackRootNavigator.Navigator>
  )
}

function DrawerNavigator() {
  return (
    <Drawer.Navigator
      drawerContent={props => <Sidebar navigation={props.navigation} />}
      // hideStatusBar={false}
      initialRouteName="Home"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Drawer.Screen name="Home" component={RootNavigator} />
      <Drawer.Screen name="contentComponent" component={Sidebar} />
    </Drawer.Navigator>
  )
}

export function MainNavigationContainer() {
  return (
    <NavigationContainer
      onReady={() => {
        if (Platform.OS !== 'web') {
          RNBootSplash.hide()
        }
      }}
      ref={navigatorRef => {
        setTopLevelNavigator(navigatorRef as never)
      }}
    >
      <StackMasterNavigator.Navigator screenOptions={{ headerShown: false }}>
        <StackMasterNavigator.Screen
          name="MasterNavigator"
          component={DrawerNavigator}
          listeners={{
            state: trackStateChange,
          }}
        />
      </StackMasterNavigator.Navigator>
    </NavigationContainer>
  )
}

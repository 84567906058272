import { Button, Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import Modal from 'react-native-modal'
import { getStatusBarHeight } from 'react-native-status-bar-height'
import AntDesign from 'react-native-vector-icons/AntDesign'
import Entypo from 'react-native-vector-icons/Entypo'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeResult } from '../../api/Theme/types'
import { i18n } from '../../lib/i18n'
import { homeTitleSelector } from '../../redux/home/selector'
import { NotificationActions } from '../../redux/notification/action'
import { currrentNotificationsSelector, hasNewNotificationFirebaseSelector } from '../../redux/notification/selector'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'
import { toDDMMYYYYatHHMM } from '../../services/dateFormatter'
import { getItemAction } from '../../services/itemAction'
import { theme } from '../../theme'

const HEIGHT = 45
const WIDTH = 45
const MARGIN_MODAL = getStatusBarHeight() + 55
const BORDER_RADIUS = 5
const FONT_SIZE = 16
const TITLE_SIZE = 18

export const ModalNotif: FunctionComponent = () => {
  const dispatch = useDispatch()
  const hasNotifFirebase = useSelector((state: RootState) => hasNewNotificationFirebaseSelector(state))
  const themeColor = useSelector((state: RootState) => themeSelector(state)) as ThemeResult
  const title = useSelector((state: RootState) => homeTitleSelector(state))
  const notification = useSelector((state: RootState) => currrentNotificationsSelector(state))

  const onClose = () => {
    dispatch(NotificationActions.storeHasNewNotificationFirebase(false))
  }

  const colorText = (themeColor && themeColor.brandPrimary) || theme.colors.silver

  const RenderImage: FunctionComponent = () =>
    notification && notification.image ? <FastImage source={{ uri: notification.image }} resizeMode={FastImage.resizeMode.contain} style={styles.iconImage} /> : null

  const RenderInfoPlus: FunctionComponent = () => {
    const onPress = () => {
      if (notification && notification.action) {
        getItemAction(notification.action)()
      }
      onClose()
    }

    return notification && notification.action ? (
      <View style={styles.iconInfo}>
        <Button variant="ghost" onPress={() => onPress()}>
          <Icon name="info-with-circle" as={Entypo} size={6} />
          <Text style={styles.textInfoPlus}>{i18n.t('notifications.moreInfo')}</Text>
        </Button>
      </View>
    ) : null
  }

  const RenderFooter: FunctionComponent = () => (notification && notification.sendDate ? <Text style={styles.footer}>{toDDMMYYYYatHHMM(notification.sendDate)}</Text> : null)

  return notification ? (
    <Modal isVisible={hasNotifFirebase} backdropOpacity={0.1} coverScreen={false} onBackButtonPress={() => onClose()} onBackdropPress={() => onClose()} style={styles.modal}>
      <View style={styles.container}>
        <View style={styles.header}>
          <RenderImage />
          <Text style={[{ color: colorText }, styles.textHeader]}>{title}</Text>
          <View style={styles.iconQuit}>
            <Button variant="ghost" onPress={() => onClose()}>
              <Icon as={AntDesign} name="closecircleo" size={6} />
            </Button>
          </View>
        </View>

        <Text style={styles.textTitle} numberOfLines={2} ellipsizeMode="tail">
          {notification.libelle}
        </Text>

        <Text style={styles.textContent} numberOfLines={4} ellipsizeMode="tail">
          {notification.message}
        </Text>
        <View style={styles.iconInfo}>
          <RenderInfoPlus />
        </View>

        <RenderFooter />
      </View>
    </Modal>
  ) : null
}

const styles = StyleSheet.create({
  modal: {
    display: 'flex',
    flex: 1,
    marginTop: MARGIN_MODAL,
    flexDirection: 'column',
  },
  container: {
    borderRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.white,
    padding: theme.padding.x2,
  },
  iconImage: {
    width: WIDTH,
    height: HEIGHT,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textHeader: { fontSize: TITLE_SIZE, paddingLeft: theme.padding.x2 },
  iconQuit: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  textTitle: { fontSize: TITLE_SIZE, color: theme.colors.black, paddingTop: theme.padding.x2 },
  textContent: {
    fontSize: FONT_SIZE,
    color: theme.colors.silverStrong,
    paddingTop: theme.padding.x2,
  },
  iconInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  textInfoPlus: {
    paddingLeft: 0,
  },
  footer: {
    color: theme.colors.silverStrong,
    paddingTop: theme.padding.x2,
  },
})
